.chat-container {
    position: relative; /* Add this */
    display: flex;
    flex-direction: column;
    height: 100%;
}

.message-area {
    flex-grow: 1;
    overflow-y: auto;
    /* padding: 10px; */
    border-bottom: 1px solid #ddd;
    max-height: calc(100% - 60px);
}

.message {
    margin-bottom: 10px;
    padding: 5px;
    background-color: #f0f0f0;
    border-radius: 5px;
}

.input-area {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    padding: 10px;
    /* background: #fff; */
}

.input-area input {
    flex-grow: 1;
    margin-right: 10px;
    padding: 5px;
}

.input-area button {
    padding: 5px 10px;
}

.input-area textarea {
    flex-grow: 1;
    margin-right: 5px;
    padding: 5px;
    resize: none; /* Disable resizing */
}

.message {
    padding: 1em;
    display: block;
    word-wrap: break-word;
    font-size: 14px;
}

.user {
    background-color: #FFFFFF;
    color: white;
    position: relative;
}

.assistant {
    background-color: #E6ECF3;
    color: white;
    position: relative;
}

.chat-input-space {
    /* display: flex; */
	justify-content: center;
	align-items: center;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10;
	width: 100%;
    background: linear-gradient(to top, #1A202C 0%, #1A202C 80%, rgba(255,0,0,0) 100%);
}